import React from "react";
import { Button } from "../button/button";
import { makeStyles } from "hooks/makeStyles";
import clsx from "clsx";

interface SwitchProps {
  id?: string;
  checked: boolean;
  onChange: (value: boolean) => void;
  defaultBgColor?: string;
  activeBgColor?: string;
}

const useStyles = makeStyles((theme) => ({
  input: {
    display: "none"
  },
  switch: {
    position: "relative",
    padding: theme.spacing(1),
    width: 51,
    height: 31,
    backgroundColor: ({ defaultBgColor }: SwitchProps) =>
      defaultBgColor || theme.colors.purple.main,
    "&:hover, &:active": {
      backgroundColor: ({ defaultBgColor }: SwitchProps) =>
        defaultBgColor || theme.colors.purple.main
    },
    "&:before": {
      content: '""',
      position: "absolute",
      left: 2,
      top: 2,
      width: 27,
      height: 27,
      borderRadius: "50%",
      backgroundColor: theme.colors.common.white,
      transition: "transform .2s, backgroundColor .2s"
    }
  },
  checked: {
    backgroundColor: ({ activeBgColor }: SwitchProps) =>
      activeBgColor || theme.colors.primary,
    "&:hover, &:active": {
      backgroundColor: ({ activeBgColor }: SwitchProps) =>
        activeBgColor || theme.colors.primary
    },
    "&:before": {
      transform: "translateX(20px)"
    }
  },
  hidden: {
    visibility: "hidden"
  }
}));

export const Switch = (props: SwitchProps) => {
  const { id, checked, onChange } = props;
  const css = useStyles(props);

  return (
    <span>
      <Button
        role="switch"
        className={clsx(css.switch, {
          [css.checked]: checked
        })}
        onClick={handleChange}
        aria-checked={checked}
      ></Button>
      <input
        id={id}
        type="checkbox"
        checked={checked}
        onChange={handleChange}
        className={css.hidden}
      />
    </span>
  );

  /**************************************************/

  function handleChange() {
    onChange(!checked);
  }
};
