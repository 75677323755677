import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useHistory, useLocation } from "react-router-dom";

import { useTranslation } from "react-i18next";

import posed from "react-pose";
import clsx from "clsx";
import { Theme } from "../../../../lib/theme";
import { makeStyles } from "../../../../hooks/makeStyles";
import { Icons } from "../../../shared/icons";
import Verification from "../../../pages/verification/verification";
import { SubscriptionSettings } from "../../../pages/subscription-settings/subscription-settings";
import NotFound from "../../../pages/not-found/not-found";

import { locationSearch, storage } from "utils";
import { subscriptionStore } from "global-stores/email-store";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    // transition: "all .1s"
  },
  appContainer: {
    width: "70%",
    maxWidth: "1280px",
    // height: "80%",
    // maxHeight: "800px",
    position: "relative",
    flexDirection: "column",
    "@media screen and (max-width: 1000px)": {
      // width: "100%"
      // height: "100vh",
      // maxHeight: "100vh"
    }
  },
  main: {
    height: `calc(100% - ${theme.sizes.header}px)`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "auto"
  },
  footer: {
    position: "fixed",
    bottom: 0,
    left: "15%",
    right: "15%",
    width: "70%",
    height: 60,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "@media screen and (max-width: 1000px)": {
      // display: "none"
    }
  },
  link: { color: theme.colors.primary, display: "flex", alignItems: "center" },
  arrow: { fill: theme.colors.primary, transform: "rotate(180deg)" },
  loader: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  }
}));

const Layout = posed.div({
  enter: {
    y: 0,
    opacity: 1,
    delay: 150
  },
  exit: {
    y: 50,
    opacity: 0,
    transition: { duration: 150 }
  }
});

export const DesktopLayout = observer(() => {
  const css = useStyles();
  const { t } = useTranslation("footer");
  const [isLoading, setIsLoading] = useState(true);
  // const [isConfirm, setIsConfirm] = useState(false);
  // const [isLoggedIn, setIsLoggedIn] = useState(false);
  // const [isWrongCode, setIsWrongCode] = useState(false);

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const params = (location.search && locationSearch(location.search)) || {};

    if (params.id && params.email) {
      subscriptionStore.setEmail.call(subscriptionStore, params.email);
      subscriptionStore.setUserId.call(subscriptionStore, params.id);
      storage.write("email", params.email);
      storage.write("id", params.id);

      if (params.code) {
        subscriptionStore
          .confirmEmail(params.code, params.email)
          .then((res: boolean) => {
            // console.log(res);
            if (res) {
              subscriptionStore.setIsConfirm(true);
              setIsLoading(false);
            }
          })
          .catch((err: any) => {
            // Not confirm
            console.log(err);
            if (err.error === "verification_alert_wrong_code") {
              subscriptionStore.setIsConfirm(true);
              subscriptionStore.setIsWrongCode(true);
              setIsLoading(false);
            }
          });
      } else {
        subscriptionStore.setIsLoggedIn(true);
        subscriptionStore.setIsConfirm(false);
        setIsLoading(false);
      }
    } else {
      if (subscriptionStore.email && subscriptionStore.userId) {
        // history.push("/settings");
        subscriptionStore.setIsLoggedIn(true);
        setIsLoading(false);
      } else {
        // Redirect 404
        subscriptionStore.setIsLoggedIn(false);
        setIsLoading(false);
      }
    }
  }, [location, history]);

  const cx = clsx({
    [css.root]: true,
    [css.appContainer]: true
  });

  return (
    <>
      {renderRoutes()}

      <div className={css.footer}>
        <a href="https://app.inside.me/" className={css.link}>
          {t("footer:title")}
          <Icons.BackArrow
            color={"#D0AC00"}
            width={28}
            height={26}
            className={css.arrow}
          />
        </a>
      </div>
    </>
  );

  /**************************************************/

  function renderRoutes() {
    if (subscriptionStore.isConfirm) {
      return (
        <Layout key="layout" className={cx}>
          <Verification handleClick={handleLoginClick} />
        </Layout>
      );
    } else if (subscriptionStore.isLoggedIn) {
      return (
        <Layout key="layout" className={cx}>
          <SubscriptionSettings />
        </Layout>
      );
    } else if (
      !subscriptionStore.isConfirm &&
      !subscriptionStore.isLoggedIn &&
      !isLoading
    ) {
      return (
        <Layout key="layout" className={cx}>
          <NotFound />
        </Layout>
      );
    } else if (isLoading) {
      return (
        <Layout key="layout" className={cx}>
          <Icons.PuffLoader
            className={css.loader}
            width={90}
            height={90}
            viewBox={"0 0 44 44"}
          />
        </Layout>
      );
    }
  }

  function handleLoginClick() {
    history.push(
      `/?id=${subscriptionStore.userId}&email=${subscriptionStore.email}`
    );
  }
});
