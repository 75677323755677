import React from "react";
import ReactDOM from "react-dom";
import { App } from "./app";
import { I18nextProvider } from "react-i18next";
import { i18n } from "./services/i18n";
import { configureTheme } from "./lib/theme";
import { ThemeProvider } from "react-jss";

const theme = configureTheme("dark");

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
