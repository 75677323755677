import React, { ButtonHTMLAttributes } from "react";
import clsx from "clsx";
import { makeStyles } from "hooks/makeStyles";
import { lighten, fade } from "utils/fade";
import { focusKeyframeMixin, focusKeyframeAnimationMixin } from "styles";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  primary?: boolean;
  secondary?: boolean;
  fullWidth?: boolean;
}

const useStyles = makeStyles((theme) => ({
  ...focusKeyframeMixin(theme),
  button: {
    width: "auto",
    padding: theme.spacing(3),
    borderRadius: 999,
    backgroundColor: theme.colors.background.paper.topbar,
    border: "none",
    fontFamily: theme.fonts.light,
    fontSize: theme.sizes.typography.body,
    textTransform: "uppercase",
    color: theme.colors.textPrimary,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: lighten(theme.colors.background.paper.topbar, 0.1)
    },
    transition: "background .2s",
    "&$disabled": {
      backgroundColor: `${fade(theme.colors.grey.main, 0.1)} !important`,
      color: `${fade(theme.colors.grey.main, 0.2)} !important`,
      pointerEvents: "none",
      cursor: "default"
    },
    "&:focus": {
      outline: "none",
      animation: focusKeyframeAnimationMixin
    }
  },
  secondary: {
    border: `1px solid ${theme.colors.textPrimary}`,
    textTransform: "capitalize"
  },
  primary: {
    backgroundColor: theme.colors.primary,
    "&:hover": {
      backgroundColor: lighten(theme.colors.primary, 0.1)
    }
  },
  fullWidth: {
    width: "100%"
  },
  disabled: {}
}));

export const Button = React.forwardRef(
  (props: ButtonProps, ref: React.Ref<HTMLButtonElement>) => {
    const css = useStyles();

    const {
      primary,
      secondary,
      fullWidth,
      children,
      className,
      ...passthrough
    } = props;

    const cx = clsx(
      css.button,
      {
        [css.fullWidth]: fullWidth,
        [css.primary]: primary,
        [css.secondary]: secondary,
        [css.disabled]: passthrough.disabled
      },
      className
    );

    return (
      <button ref={ref} className={cx} {...passthrough}>
        {children}
      </button>
    );
  }
);
