import React from "react";
import { observer } from "mobx-react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Background } from "./ui/components/background/background";
import { makeStyles } from "./hooks/makeStyles";
import { DesktopLayout } from "ui/components/layout/desktop-layout/desktop-layout";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      margin: 0,
      padding: 0,
    },
    html: {
      fontSize: "10px",
      fontFamily: theme.fonts.regular,
      color: theme.colors.textPrimary,
    },
    a: {
      color: theme.colors.textSecondary,
      fontSize: "1.5rem",
    },
    "@font-face": [
      {
        fontFamily: theme.fonts.light,
        src: 'url("/fonts/SFUIText-Light.eot"), url("/fonts/SFUIText-Light.eot?#iefix") format("embedded-opentype"), url("/fonts/SFUIText-Light.woff") format("woff"), url("/fonts/SFUIText-Light.ttf") format("truetype")',
        fontWeight: 300,
        fontStyle: "normal",
      },
      {
        fontFamily: theme.fonts.regular,
        src: 'url("/fonts/SFUIText-Regular.eot"), url("/fonts/SFUIText-Regular.eot?#iefix") format("embedded-opentype"), url("/fonts/SFUIText-Regular.woff") format("woff"), url("/fonts/SFUIText-Regular.ttf") format("truetype")',
        fontWeight: "normal",
        fontStyle: "normal",
      },
      {
        fontFamily: theme.fonts.medium,
        src: 'url("/fonts/SFUIText-Medium.eot"), url("/fonts/SFUIText-Medium.ttf") format("truetype"), url("/fonts/SFUIText-Medium.eot?#iefix") format("embedded-opentype"), url("/fonts/SFUIText-Medium.woff") format("woff")',
        fontStyle: "normal",
      },
      {
        fontFamily: theme.fonts.semibold,
        src: 'url("/fonts/SFUIText-Semibold.eot"), url("/fonts/SFUIText-Semibold.eot?#iefix") format("embedded-opentype"), url("/fonts/SFUIText-Semibold.woff") format("woff"), url("/fonts/SFUIText-Semibold.ttf") format("truetype")',
        fontStyle: "normal",
      },
      {
        fontFamily: theme.fonts.script,
        src: 'url("/fonts/segoesc.ttf")',
        fontWeight: "normal",
        fontStyle: "normal",
      },
    ],
  },
  root: {
    width: "100%",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
  },
}));

export const App = observer(() => {
  const css = useStyles();

  return (
    <Router>
      <div className={css.root}>
        <React.Suspense fallback={null}>
          <Route path="/" component={DesktopLayout} />
        </React.Suspense>
        <Background />
      </div>
    </Router>
  );
});
