import { fade } from "utils";

export const darkTheme: Theme = {
  fonts: {
    light: "SFUIText-Light",
    regular: "SFUIText-Regular",
    medium: "SFUIText-Medium",
    semibold: "SFUIText-Semibold",
    script: "SegoeSC",
  },
  colors: {
    background: {
      paper: {
        default: "#fff",
        dark: "#0A2C43",
        darker: "#211D27",
        lighter: "#45385B",
        topbar: "#322941",
        main: "#15131E",
      },
      default: "#06263f",
      dark: "#15131E",
    },
    common: {
      white: "#fff",
      black: "#000",
    },
    textPrimary: "#fff",
    textSecondary: fade("#fff", 0.6),
    textDisabled: "#90A4AE",
    textBlack: "#2C2C2C",
    primary: "#D0AC00",
    green: {
      dark: "#71A20D",
      main: "#94C234",
      light: "#B6E060",
      secondary: "#8FD82D",
    },
    red: {
      dark: "#DB4300",
      main: "#FF4E00",
      light: "#FF7C42",
      secondary: "#FF3C26",
    },
    purple: {
      main: "#655285",
    },
    grey: {
      main: "#BDC6CC",
    },
    backdrop: fade("#211D27", 0.9),
  },
  sizes: {
    headerMargin: 54,
    header: 60,
    footer: 52,
    borderRadius: {
      default: 4,
      medium: 10,
    },
    typography: {
      tiny: "10px",
      caption: "12px",
      body: "14px",
      subtitle1: "18px",
      subtitle2: "16px",
      h1: "33px",
      h2: "23px",
      h3: "20px",
    },
  },
  shadows: {
    big: `0px 6px 6px -3px rgba(0,0,0,0.2), 0px 10px 14px 1px rgba(0,0,0,0.14), 0px 4px 18px 3px rgba(0,0,0,0.12)`,
    dropdown: `0px 3px 6px #000029`,
    panel: `0px 3px 6px #000029`,
  },
  spacing(value: number) {
    return `${value * this.unit}px`;
  },
  unit: 4,
};

export const configureTheme = (type: ThemeType): Theme => {
  return type === "dark" ? darkTheme : darkTheme;
};

export interface Theme {
  fonts: Fonts;
  colors: Colors;
  shadows: Shadows;
  sizes: Sizes;
  spacing: (value: number) => string;
  unit: number;
}

export type ThemeType = "dark" | "light";

interface Colors {
  background: {
    paper: {
      dark: string;
      darker: string;
      topbar: string;
      lighter: string;
      default: string;
      main: string;
    };
    default: string;
    dark: string;
  };
  common: {
    white: string;
    black: string;
  };
  textPrimary: string;
  textSecondary: string;
  textDisabled: string;
  textBlack: string;
  primary: string;
  green: {
    dark: string;
    main: string;
    light: string;
    secondary: string;
  };
  red: {
    dark: string;
    main: string;
    light: string;
    secondary: string;
  };
  purple: {
    main: string;
  };
  grey: {
    main: string;
  };
  backdrop: string;
}

interface Shadows {
  big: string;
  dropdown: string;
  panel: string;
}

interface Fonts {
  light: string;
  regular: string;
  medium: string;
  semibold: string;
  script: string;
}

export interface Sizes {
  headerMargin: number;
  header: number;
  footer: number;
  borderRadius: {
    default: number;
    medium: number;
  };
  typography: {
    tiny: string;
    caption: string;
    body: string;
    subtitle1: string;
    subtitle2: string;
    h1: string;
    h2: string;
    h3: string;
  };
}
