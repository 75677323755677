import * as React from "react";
import { CreateSvgIcon } from "../icon";
import { IconNames, IconProps } from "../names";

export const Like = (props: IconProps) => (
  <CreateSvgIcon {...props} data-cy={IconNames.Like}>
    {({ color }) => (
      <>
        <g>
          <path
            d="M4.08,18.57a3.35,3.35,0,0,0,.63,2,3.62,3.62,0,0,0,0,1.73,2.54,2.54,0,0,0,.83,1.38,3.46,3.46,0,0,0,.76,2.7,3.62,3.62,0,0,0,3,1.18h2a12.82,12.82,0,0,0,2.14-.21c.76-.14,1.38-.28,1.87-.42s1-.34,1.8-.55a9.57,9.57,0,0,1,2.42-.62,1.26,1.26,0,0,0,.69-.27.94.94,0,0,0,.27-.69V15.25a.68.68,0,0,0-.27-.62.8.8,0,0,0-.63-.27c-.2,0-.62-.35-1.17-.9a18.67,18.67,0,0,1-1.52-1.81c-.69-.83-1.18-1.45-1.52-1.8a2.41,2.41,0,0,1-.49-.69c-.13-.27-.21-.55-.27-.69a6.55,6.55,0,0,1-.21-.9,5.81,5.81,0,0,0-.2-.9,6.86,6.86,0,0,0-.27-.76,8.9,8.9,0,0,0-.49-.76.93.93,0,0,0-.69-.27A6.56,6.56,0,0,0,11.49,5a2.67,2.67,0,0,0-.89.41,2.51,2.51,0,0,0-.63.63,5.52,5.52,0,0,0-.34.69,4.73,4.73,0,0,0-.21.76,3.17,3.17,0,0,0-.07.69v.55a6,6,0,0,0,.14,1.11,7.69,7.69,0,0,0,.27.9,4.27,4.27,0,0,0,.41.83c0,.07.07.13.14.27a.8.8,0,0,1,.13.34c.07.07.07.21.14.35H6.36a3,3,0,0,0-2.08.83,2.07,2.07,0,0,0-.82,1.8,3.28,3.28,0,0,0,.83,2.21A4.72,4.72,0,0,0,4.08,18.57Z"
            transform="translate(-2.11 -2.18)"
            fill={color}
          />
          <path
            d="M11.28,26.21a10.18,10.18,0,0,0,1.9-.19c.7-.13,1.27-.25,1.71-.38s1-.34,1.8-.55a14.36,14.36,0,0,1,2.43-.66v-8.8a4.36,4.36,0,0,1-1.68-1.23,18.85,18.85,0,0,1-1.61-1.9c-.21-.24-.39-.47-.56-.67-.38-.46-.67-.83-.87-1a4.05,4.05,0,0,1-.74-1c-.11-.24-.19-.46-.25-.62l0-.1,0-.06,0-.09a7.15,7.15,0,0,1-.25-1.11,5.6,5.6,0,0,0-.16-.69l-.06-.19c-.05-.14-.09-.28-.14-.39a3.31,3.31,0,0,0-.18-.29,4.21,4.21,0,0,0-.66.09l-.21.07-.18.06,0,0-.11.07a1.64,1.64,0,0,0-.22.23L11,7a1.81,1.81,0,0,0-.1.2,3.37,3.37,0,0,0-.17.6v.06a1.63,1.63,0,0,0,0,.36v.56a5.19,5.19,0,0,0,.11.84c0,.17.11.38.17.55s0,.13.07.19a2.32,2.32,0,0,0,.29.56l.19.32v.07a1.86,1.86,0,0,1,.21.48c0,.1.06.18.08.24l.95,1.9H6.37a1.72,1.72,0,0,0-1.18.49l-.09.07L5,14.53a.85.85,0,0,0-.2.65,1.92,1.92,0,0,0,.49,1.31l.49.56-.2.71a3.17,3.17,0,0,0-.16.81,1.89,1.89,0,0,0,.36,1.19l.38.52L6,20.9A2.23,2.23,0,0,0,6,22a1.29,1.29,0,0,0,.38.66l.53.43,0,.69a2.14,2.14,0,0,0,.42,1.71l0,0,0,0c.14.16.56.68,1.93.68h2m0,1.35h-2a3.6,3.6,0,0,1-3-1.18,3.51,3.51,0,0,1-.76-2.7,2.55,2.55,0,0,1-.83-1.38,3.61,3.61,0,0,1,0-1.73,3.25,3.25,0,0,1-.63-2,4.15,4.15,0,0,1,.21-1.18,3.27,3.27,0,0,1-.83-2.21,2.07,2.07,0,0,1,.83-1.8,3.07,3.07,0,0,1,2.08-.83h4.21c-.06-.14-.06-.28-.13-.35s-.07-.2-.14-.34-.14-.21-.14-.28a4.28,4.28,0,0,1-.42-.83,8.67,8.67,0,0,1-.27-.9,5.92,5.92,0,0,1-.14-1.1V8.19a2.87,2.87,0,0,1,.07-.69,5.91,5.91,0,0,1,.21-.76A6.15,6.15,0,0,1,10,6.05a2.69,2.69,0,0,1,1.52-1,6.24,6.24,0,0,1,1.25-.13.88.88,0,0,1,.69.27,6.65,6.65,0,0,1,.48.76,5.71,5.71,0,0,1,.28.76,6.12,6.12,0,0,1,.21.9,7.7,7.7,0,0,0,.2.9c.07.14.14.42.28.69a2.33,2.33,0,0,0,.49.69c.34.35.83,1,1.52,1.8a17.43,17.43,0,0,0,1.52,1.8c.55.55,1,.9,1.17.9a.86.86,0,0,1,.63.28.67.67,0,0,1,.27.62v9.54a.9.9,0,0,1-.27.69,1.24,1.24,0,0,1-.7.28,10.23,10.23,0,0,0-2.42.62c-.76.21-1.38.41-1.79.55s-1.11.28-1.87.42A11.65,11.65,0,0,1,11.28,27.56Z"
            transform="translate(-2.11 -2.18)"
            fill="#dbe3eb"
          />
        </g>
        <g>
          <path
            d="M23,14.56a.86.86,0,0,0-.27.69v9.47a.88.88,0,0,0,.27.7.93.93,0,0,0,.69.27h4.36a.86.86,0,0,0,.69-.27,1,1,0,0,0,.27-.7V15.18a.88.88,0,0,0-.27-.7.93.93,0,0,0-.69-.27H23.69A1.38,1.38,0,0,0,23,14.56Z"
            transform="translate(-2.11 -2.18)"
            fill={color}
          />
          <path
            d="M24.07,15.56v8.78h3.59V15.56H24.07m-.38-1.35h4.36a.94.94,0,0,1,1,1v9.54a1,1,0,0,1-.27.69.89.89,0,0,1-.69.28H23.69a1,1,0,0,1-1-1V15.25a.86.86,0,0,1,.28-.69A1.3,1.3,0,0,1,23.69,14.21Z"
            transform="translate(-2.11 -2.18)"
            fill="#dbe3eb"
          />
        </g>
      </>
    )}
  </CreateSvgIcon>
);
