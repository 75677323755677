import * as React from "react";
import { CreateSvgIcon } from "../icon";
import { IconNames, IconProps } from "../names";

export const Facebook = (props: IconProps) => (
  <CreateSvgIcon {...props} data-cy={IconNames.Facebook}>
    {({ color }) => (
      <>
        <g>
          <circle cx="12" cy="12" r="12" fill="none" />
          <circle cx="12" cy="12" r="11.5" fill="none" stroke="#fff" />
        </g>
        <path
          d="M13.1,13.64h1.43V12.35a3.15,3.15,0,0,1,.45-2,2.6,2.6,0,0,1,2.23-1,9.81,9.81,0,0,1,2.59.24l-.35,2a5,5,0,0,0-1.15-.17c-.56,0-1.05.17-1.05.7v1.5h2.27l-.18,1.92H17.21v6.73H14.53v-6.7H13.1Z"
          transform="translate(-4.47 -3.72)"
          fill="#fff"
        />
      </>
    )}
  </CreateSvgIcon>
);
