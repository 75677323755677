import * as React from "react";
import { CreateSvgIcon } from "../icon";
import { IconNames, IconProps } from "../names";

export const Star = (props: IconProps) => (
  <CreateSvgIcon {...props} data-cy={IconNames.Star}>
    {({ color, secondaryColor = "none" }) => (
      <g>
        <path
          d="M16,3.65l4,8.09L29,13l-6.5,6.35,1.59,9L16,24.16,7.91,28.35l1.59-9L3,13l9-1.3Z"
          transform="translate(0)"
          fill={secondaryColor}
        />
        <path
          d="M16,7l-2.74,5.49-.29.72-.73.15-6.06.86,4.33,4.34.58.58-.15.72-1,6.06L15.42,23l.72-.29.73.29,5.49,2.88-1-6.06L20.91,19l.58-.58,4.33-4.33-6.06-.87L19,13l-.29-.72L16,7m0-3.32,4,8.09L29,13l-6.5,6.35,1.59,9L16,24.16,7.91,28.35l1.59-9L3,13l9-1.3Z"
          transform="translate(0)"
          fill="#dbe3eb"
        />
      </g>
    )}
  </CreateSvgIcon>
);
