import React from "react";
import { makeStyles } from "../../../hooks/makeStyles";
import { Typography, Switch } from "../../shared";
import { SubscriptionOption } from "../../../lib/common-interfaces/types";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

export interface SubscriptionSettingsItemProps {
  //   id: string;
  //   label: string;
  //   checked?: boolean;
  item: SubscriptionOption;
  onClick: (item: SubscriptionOption) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: 40,
    "&:not(:last-child)": {
      marginBottom: `${theme.spacing(2.75)}`
    }
  },
  label: {
    fontSize: 18,
    cursor: "pointer"
  }
}));

export const SubscriptionSettingsItem = observer(
  (props: SubscriptionSettingsItemProps) => {
    const { t } = useTranslation("settings");
    const {
      //   id, label, checked,
      item,
      onClick
    } = props;
    const css = useStyles();

    return (
      <div className={css.root}>
        <Typography htmlFor={item._id} component="label" className={css.label}>
          {t("settings:lang") === "en" ? item.en : item.ru}
        </Typography>
        <Switch
          id={item._id}
          checked={item.subscribe}
          onChange={() => onClick(item)}
        />
      </div>
    );
  }
);
