import * as React from "react";
import { CreateSvgIcon } from "../icon";
import { IconNames, IconProps } from "../names";

export const Twitter = (props: IconProps) => (
  <CreateSvgIcon {...props} data-cy={IconNames.Twitter}>
    {({ color }) => (
      <>
        <g>
          <circle cx="12" cy="12" r="12" fill="none" />
          <circle cx="12" cy="12" r="11.5" fill="none" stroke="#fff" />
        </g>
        <path
          d="M23.33,11.68a5,5,0,0,1-1.7.45,2.88,2.88,0,0,0,1.3-1.61,5.73,5.73,0,0,1-1.88.72,2.89,2.89,0,0,0-2.14-.94A2.94,2.94,0,0,0,16,13.24h0a3.73,3.73,0,0,0,.09.67A8.38,8.38,0,0,1,10,10.84a2.71,2.71,0,0,0-.4,1.47,3,3,0,0,0,1.29,2.45,2.74,2.74,0,0,1-1.34-.36v0a3,3,0,0,0,2.36,2.9,4.8,4.8,0,0,1-.75.09,2.47,2.47,0,0,1-.54-.05,3,3,0,0,0,2.72,2.05,5.84,5.84,0,0,1-3.66,1.25A3.44,3.44,0,0,1,9,20.64a8.31,8.31,0,0,0,12.84-7v-.4A5.38,5.38,0,0,0,23.33,11.68Z"
          transform="translate(-3.95 -3.66)"
          fill="#fff"
        />
      </>
    )}
  </CreateSvgIcon>
);
