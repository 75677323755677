export const capitalize = (str: string) =>
  str && str[0].toUpperCase() + str.slice(1);

export const locationSearch = (str: string) =>
  JSON.parse(
    '{"' +
      decodeURI(str)
        .replace("?", "")
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"') +
      '"}'
  );
