import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

import { makeStyles } from "../../../hooks/makeStyles";
import { Typography } from "../../shared/typography/typography";

import notFoundImg from "../../../assets/svg/404.svg";

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  },
  logo: {
    "@media screen and (max-width: 700px)": {
      width: "100%"
    }
  },
  title: {
    fontSize: 30,
    marginTop: `${theme.spacing(10)}`,
    color: theme.colors.textPrimary,
    "@media screen and (max-width: 700px)": {
      fontSize: 20
    }
  }
}));

const NotFound = observer(() => {
  const css = useStyles();
  const { t } = useTranslation("notfound");

  return (
    <div className={css.main}>
      <img src={notFoundImg} alt="not found" className={css.logo} />

      <Typography className={css.title} align="center">
        {t("notfound:title")}
      </Typography>
    </div>
  );
});

export default NotFound;
