import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

import { makeStyles } from "../../../hooks/makeStyles";
import { Typography } from "../../shared/typography/typography";
import logo from "../../../assets/svg/logo-inside-me.svg";

import { subscriptionStore } from "global-stores/email-store";

export interface VerificationProps {
  handleClick: () => void;
}

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%"
    // padding: `${theme.spacing(3)} ${theme.spacing(6)}`,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: `${theme.spacing(7)} ${theme.spacing(8.5)}`,
    backgroundColor: theme.colors.background.paper.topbar,
    borderRadius: "10px"
  },
  logo: {
    width: 220,
    marginBottom: `${theme.spacing(10)}`
  },
  title: {
    fontSize: 24,
    marginBottom: `${theme.spacing(4)}`
  },
  subtitle: {
    fontSize: 20,
    marginBottom: `${theme.spacing(10)}`
  },
  link: {
    fontSize: 16,
    color: theme.colors.textPrimary,
    background: "none",
    border: "none",
    textDecoration: "underline",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "none"
    }
  }
}));

const Verification = observer((props: VerificationProps) => {
  const { handleClick } = props;
  const css = useStyles();
  const { t } = useTranslation("verification");

  return (
    <div className={css.main}>
      <div className={css.container}>
        <img className={css.logo} src={logo} alt="inside.me" />
        {!subscriptionStore.isWrongCode ? (
          <Typography className={css.title}>
            {t("verification:title")}
          </Typography>
        ) : null}

        <Typography className={css.subtitle}>
          {!subscriptionStore.isWrongCode
            ? t("verification:subtitle")
            : t("verification:error")}
        </Typography>
        <button className={css.link} onClick={() => handleClick()}>
          {t("verification:link")}
        </button>
      </div>
    </div>
  );
});

export default Verification;
