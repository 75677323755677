import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "../../../hooks/makeStyles";
import { Typography } from "../../shared/typography/typography";
import { capitalize } from "../../../utils";
import logo from "../../../assets/svg/logo-inside-me.svg";
import ItemCart from "./ItemCart";
import { observer } from "mobx-react";
import { subscriptionStore } from "global-stores/email-store";
import NotFound from "../not-found/not-found";

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    flexDirection: "column",
    marginTop: `${theme.spacing(44.25)}`,
    maxHeight: "80%",
    "@media screen and (max-width: 919px)": {
      marginTop: `${theme.spacing(22.1)}`
    },
    "@media screen and (max-width: 425px)": {
      marginTop: `${theme.spacing(11)}`
    }
  },
  header: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: `${theme.spacing(3.25)}`,
    "@media screen and (max-width: 425px)": {
      flexDirection: "column",
      justifyContent: "center"
    }
  },
  logo: {
    width: 126,
    height: 51,
    marginRight: `${theme.spacing(6)}`
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "@media screen and (max-width: 425px)": {
      textAlign: "center"
    }
  },
  title: {
    fontSize: 24,
    textTransform: "uppercase",
    marginBottom: `${theme.spacing(1.5)}`
  },
  name: {
    fontSize: 16
  },
  subtitle: {
    fontSize: 20
  },
  link: {
    fontSize: 16,
    // textDecoration: "underline"
    color: theme.colors.textPrimary,
    "&:hover": {
      textDecoration: "none"
    }
  },
  container: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    width: "100%",
    height: `calc(100% - ${theme.sizes.headerMargin}px)`,
    overflow: "auto"
  }
}));

export const SubscriptionSettings = observer(() => {
  const { t } = useTranslation("settings");
  const css = useStyles();
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    subscriptionStore.getEmailList().catch((err: any) => {
      console.log(err);
      subscriptionStore.setIsLoading(false);
      if (err) {
        setIsError(true);
      }
    });
  }, []);

  if (subscriptionStore.loading == null || subscriptionStore.loading)
    return null;

  const keys = Object.keys(subscriptionStore.data);

  if (isError) {
    console.log(isError);
    return <NotFound />;
  }

  return (
    <div className={css.main}>
      <div className={css.header}>
        <img className={css.logo} src={logo} alt="inside.me logo" />
        <div className={css.wrapper}>
          <Typography className={css.title}>
            {capitalize(t("settings:title"))}
          </Typography>
          <Typography className={css.name}>
            {subscriptionStore.email}
          </Typography>
        </div>
      </div>
      <div className={css.container}>
        {keys.map((key, i) => (
          <ItemCart key={i} title={key} dataKey={key} />
        ))}
      </div>
    </div>
  );
});
