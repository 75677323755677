import { observable, action } from "mobx";
import { server, storage } from "../utils";
import { SubscriptionOption } from "../lib/common-interfaces/types";

class SubscriptionStore {
  constructor() {
    this.email = storage.read("email");
    this.userId = storage.read("id");
  }

  @observable loading?: boolean;
  @observable email?: string;
  @observable userId?: string | undefined;
  @observable data: { [key: string]: SubscriptionOption[] } = {};
  @observable error?: string = "";
  @observable displayItems: string[] = [];

  @observable isLoadingUi?: boolean = true;
  @observable isConfirm?: boolean;
  @observable isLoggedIn?: boolean;
  @observable isWrongCode?: boolean;

  @action setIsLoadingUi(value: boolean) {
    this.isLoadingUi = value;
  }

  @action setIsConfirm(value: boolean) {
    this.isConfirm = value;
  }

  @action setIsLoggedIn(value: boolean) {
    this.isLoggedIn = value;
  }

  @action setIsWrongCode(value: boolean) {
    this.isWrongCode = value;
  }

  @action setEmail(email: string) {
    this.email = email;
  }

  @action setUserId(id: string) {
    this.userId = id;
  }

  @action confirmEmail(code: string, email: string) {
    return server.call("emailVerification.confirm", {
      email,
      code
    });
  }

  @action async getEmailList() {
    this.loading = true;
    const emailList = await server.call("email.getList", {
      id: this.userId,
      email: this.email
    });

    if (Array.isArray(emailList)) {
      this.data = emailList.reduce((acc, item) => {
        return {
          ...acc,
          [item.type]: acc[item.type] ? acc[item.type].concat(item) : [item]
        };
      }, {});
    }

    this.loading = false;
  }

  @action setIsLoading(loading: boolean) {
    this.loading = loading;
  }

  @action
  setDisplayItems(value: string[]) {
    this.displayItems = value;
  }

  @action toggleSubscriptionItem(item: SubscriptionOption) {
    item.subscribe = !item.subscribe;
  }

  @action removeSub(item: SubscriptionOption) {
    server
      .call("email.removeFromList", {
        email: this.email,
        id: this.userId,
        listName: item.listName
      })
      .catch((err: any) => {
        // console.log(err);
        this.toggleSubscriptionItem(item);
      });
  }

  @action async addSub(item: SubscriptionOption) {
    server
      .call("email.addToList", {
        email: this.email,
        id: this.userId,
        listName: item.listName
      })
      .catch((err: any) => {
        console.log(err);
        this.toggleSubscriptionItem(item);
      });
  }

  @action async toggleSubscription(item: SubscriptionOption) {
    if (this.email && this.userId) {
      if (item.subscribe) {
        this.removeSub(item);
      } else {
        this.addSub(item);
      }
    }
  }
}

export const subscriptionStore = new SubscriptionStore();
