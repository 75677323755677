const simpleDDP = require("simpleddp"); // nodejs
const ws = require("isomorphic-ws");

let opts = {
  endpoint: process.env.REACT_APP_SERVER,
  // endpoint: "ws://192.168.20.50:3000/websocket",
  SocketConstructor: ws,
  reconnectInterval: 5000
};
export const server = new simpleDDP(opts);
