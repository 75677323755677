import React, { HTMLAttributes } from "react";
import { makeStyles } from "../../../hooks/makeStyles";
import clsx from "clsx";
import { Sizes } from "../../../lib/theme";

type SizeOptions =
  | "tiny(10px)"
  | "caption(12px)"
  | "body(14px)"
  | "subtitle1(18px)"
  | "subtitle2(16px)"
  | "h3(20px)"
  | "h2(23px)"
  | "h1(33px)";

interface TypographyProps extends HTMLAttributes<HTMLElement> {
  size?: SizeOptions;
  font?: "script" | "light" | "regular" | "medium" | "semibold";
  align?: "center" | "left" | "right" | "justify";
  color?: "primary" | "secondary" | "disabled" | "black" | "white" | "yellow";
  uppercase?: boolean;
  htmlFor?: string;
  component?: "label" | "h1" | "h2";
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center"
  },
  default: {
    color: theme.colors.textPrimary,
    margin: 0
  },
  base: {
    fontFamily: ({ font = "regular" }: TypographyProps) => theme.fonts[font],
    fontSize: ({ size = "body(14px)" }: TypographyProps) =>
      theme.sizes.typography[getSize(size)]
  },
  alignCenter: {
    textAlign: "center"
  },
  alignLeft: {
    textAlign: "left"
  },
  alignRight: {
    textAlign: "right"
  },
  alignJustify: {
    textAlign: "justify"
  },
  uppercase: {
    textTransform: "uppercase"
  },
  primary: {
    color: theme.colors.textPrimary
  },
  secondary: {
    color: theme.colors.textSecondary
  },
  black: {
    color: theme.colors.textBlack
  },
  white: {
    color: theme.colors.common.white
  },
  yellow: {
    color: theme.colors.primary
  },
  disabled: {
    color: theme.colors.textDisabled
  }
}));

export const Typography = (props: TypographyProps) => {
  const css = useStyles(props);

  const {
    align,
    color = "primary",
    uppercase,
    component,
    htmlFor,
    className,
    ...passthrough
  } = props;

  const cx = clsx(css.default, css.base, {
    [css.alignCenter]: align === "center",
    [css.alignLeft]: align === "left",
    [css.alignRight]: align === "right",
    [css.alignJustify]: align === "justify",
    [css.primary]: color === "primary",
    [css.secondary]: color === "secondary",
    [css.disabled]: color === "disabled",
    [css.black]: color === "black",
    [css.white]: color === "white",
    [css.yellow]: color === "yellow",
    [css.uppercase]: uppercase,
    [className!]: !!className
  });

  const Component = component ? component : "p";

  return (
    <Component htmlFor={htmlFor} className={cx} {...passthrough}>
      {props.children}
    </Component>
  );
};

function getSize(size: SizeOptions): keyof Sizes["typography"] {
  return size.split("(")[0] as keyof Sizes["typography"];
}
