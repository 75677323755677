import * as React from "react";
import { CreateSvgIcon } from "../icon";
import { IconNames, IconProps } from "../names";

export const VIP = (props: IconProps) => (
  <CreateSvgIcon {...props} data-cy={IconNames.VIP}>
    {({ color }) => (
      <g>
        <g>
          <path
            d="M16.24,3,5.74,7.08V17.22s.6,7.08,10.5,11c9.9-3.9,10.5-11,10.5-11V7.08Z"
            transform="translate(0 0.02)"
            fill="#cfab00"
          />
          <path
            d="M11.8,23.34l8.7-.06,3-8.88-5,3.36-2.34-9.9-2.34,9.9L9,14.4Z"
            transform="translate(0 0.02)"
            fill="#fff"
          />
        </g>
        <rect x="2.62" y="2" width="27.24" height="27.24" fill="none" />
      </g>
    )}
  </CreateSvgIcon>
);
