import * as React from "react";
import { CreateSvgIcon } from "../icon";
import { IconNames, IconProps } from "../names";

export const Cross = (props: IconProps) => (
  <CreateSvgIcon {...props} data-cy={IconNames.Cross}>
    {({ color }) => (
      <polygon
        fill={color}
        points="15.7,0.7 15,0 7.9,7.1 0.7,0 0,0.7 7.1,7.9 0,15 0.7,15.7 7.9,8.6 15,15.7 15.7,15 8.6,7.9 "
      />
    )}
  </CreateSvgIcon>
);
