import * as React from "react";
import { CreateSvgIcon } from "../icon";
import { IconNames, IconProps } from "../names";

export const QuestionCircle = (props: IconProps) => (
  <CreateSvgIcon {...props} data-cy={IconNames.QuestionCircle}>
    {({ color }) => (
      <path
        id="Info"
        d="M16,29.7a13.68,13.68,0,1,1,9.68-4A13.68,13.68,0,0,1,16,29.7ZM16,3.58A12.44,12.44,0,1,0,28.46,16h0A12.45,12.45,0,0,0,16,3.58Zm-.46,20.2A1.36,1.36,0,0,1,14,22.63a1.15,1.15,0,0,1,0-.37,1.36,1.36,0,0,1,1.15-1.53,2.41,2.41,0,0,1,.38,0,1.35,1.35,0,0,1,1.52,1.15,1.21,1.21,0,0,1,0,.38,1.35,1.35,0,0,1-1.15,1.52A1.15,1.15,0,0,1,15.56,23.78Zm1-5.07H14.37V16.16a1.93,1.93,0,0,1,.51-1.35,3.65,3.65,0,0,1,1.19-1l.57-.41c.54-.36.8-.54.95-1.11v-.51c0-.87-.54-1.35-1.52-1.35a3.75,3.75,0,0,0-2.55,1.35l-.67-2.2A5.44,5.44,0,0,1,16.4,8.21a3.58,3.58,0,0,1,2.71,1,3.49,3.49,0,0,1,1,2.54,3.94,3.94,0,0,1-.51,1.87l-1.15,1.14-1.22.89a1.7,1.7,0,0,0-.68,1.18v1.87Z"
        transform="translate(0 0.02)"
        fill="#fff"
      />
    )}
  </CreateSvgIcon>
);
