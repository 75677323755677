const write = (key: string, value?: any) => {
  try {
    let parsedValue = value;
    if (typeof value !== "string") {
      parsedValue = JSON.stringify(value);
    }
    localStorage.setItem(key, parsedValue);
  } catch (err) {
    console.error(err);
  }
};

const read = (key: string, json?: boolean): any => {
  try {
    const value = localStorage.getItem(key);
    if (!value) return undefined;
    return json ? JSON.parse(value) : value;
  } catch (err) {
    console.error(err);
  }
};

const clear = (key: string | string[]) => {
  try {
    if (Array.isArray(key)) {
      key.forEach((item) => {
        localStorage.removeItem(item);
      });
    } else {
      localStorage.removeItem(key);
    }
  } catch (err) {
    console.error(err);
  }
};

const clearAll = () => {
  clear(["email", "id"]);
};

export const storage = {
  write,
  read,
  clear,
  clearAll
};
