import * as React from "react";
import { CreateSvgIcon } from "../icon";
import { IconNames, IconProps } from "../names";

export const VK = (props: IconProps) => (
  <CreateSvgIcon {...props} data-cy={IconNames.VK}>
    {({ color }) => (
      <>
        <g>
          <circle cx="12" cy="12" r="12" fill="none" />
          <circle cx="12" cy="12" r="11.5" fill="none" stroke="#fff" />
        </g>
        <path
          d="M16.28,19.93h.87a1,1,0,0,0,.39-.18.68.68,0,0,0,.13-.39s0-1.17.52-1.34,1.25,1.17,2,1.65a1.43,1.43,0,0,0,1,.3l2,0s1,0,.56-.86a6.44,6.44,0,0,0-1.47-1.69c-1.26-1.18-1.09-1,.43-3,.91-1.22,1.26-1.95,1.17-2.26s-.78-.22-.78-.22H20.87a.46.46,0,0,0-.3.05.75.75,0,0,0-.21.26A11,11,0,0,1,19.53,14c-1,1.7-1.39,1.78-1.56,1.7-.35-.3-.26-1-.26-1.57,0-1.64.26-2.3-.48-2.47a5.15,5.15,0,0,0-1-.08,5.58,5.58,0,0,0-1.86.17c-.26.13-.43.39-.35.43a.9.9,0,0,1,.65.35,2.15,2.15,0,0,1,.22,1s.13,1.91-.3,2.17-.69-.22-1.56-1.74a12.73,12.73,0,0,1-.83-1.65.55.55,0,0,0-.52-.39H9.51a.83.83,0,0,0-.43.13c-.09.13,0,.39,0,.39a23.26,23.26,0,0,0,3.56,5.86,5,5,0,0,0,3.64,1.65"
          transform="translate(-4.46 -3.74)"
          fill="#fff"
        />
      </>
    )}
  </CreateSvgIcon>
);
