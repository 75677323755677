export enum IconNames {
  "Smile" = "Smile",
  "BackArrow" = "BackArrow",
  "QuestionCircle" = "QuestionCircle",
  "Dislike" = "Dislike",
  "Like" = "Like",
  "Facebook" = "Facebook",
  "WarningCircle" = "WarningCircle",
  "PauseCircle" = "PauseCircle",
  "QRCode" = "QRCode",
  "Settings" = "Settings",
  "Twitter" = "Twitter",
  "Verified" = "Verified",
  "VIP" = "VIP",
  "VK" = "VK",
  "OK" = "OK",
  "Checkmark" = "Checkmark",
  "Karma" = "Karma",
  "NotActiveKarma" = "NotActiveKarma",
  "ActiveKarma" = "ActiveKarma",
  "Trash" = "Trash",
  "Dots" = "Dots",
  "AddAvatar" = "AddAvatar",
  "Cross" = "Cross",
  "Photo" = "Photo",
  "Send" = "Send",
  "Star" = "Star",
  "Option" = "Option",
  "PuffLoader" = "PuffLoader",
  "Typing" = "Typing",
  "RevertArrow" = "RevertArrow",
  "TriangleLoader" = "TriangleLoader"
}

export type IconProps = {
  width?: number;
  height?: number;
  viewBox?: string;
  color?: string;
  secondaryColor?: string;
  ["data-cy"]?: string;
  hover?: boolean;
  className?: string;
};
