import React from "react";
import { makeStyles } from "../../../hooks/makeStyles";
import { Typography } from "../../shared/typography/typography";
import { SubscriptionSettingsItem } from "./subscription-settings-item";
import { useTranslation } from "react-i18next";
import { fade } from "../../../utils";
import { SubscriptionOption } from "../../../lib/common-interfaces/types";
import { observer } from "mobx-react";
import { subscriptionStore } from "global-stores/email-store";

export interface ItemCartProps {
  title: string;
  dataKey: string;
}

const useStyles = makeStyles((theme) => ({
  panelContainer: {
    maxWidth: "400px",
    // minWidth: "300px",
    borderRadius: 10,
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    overflow: "hidden",
    margin: `${theme.spacing(5.5)} ${theme.spacing(2.75)} ${theme.spacing(
      0
    )} ${theme.spacing(2.75)}`,
    "&:focus": {
      outline: "none"
    },
    // maxHeight: "calc(100% - 23px - 45px)"
    height: "fit-content",
    "@media screen and (max-width: 440px)": {
      margin: `${theme.spacing(2.5)} ${theme.spacing(1.35)} ${theme.spacing(
        0
      )} ${theme.spacing(1.35)}`
    }
  },
  scrollContainer: {
    height: "100%",
    boxSizing: "border-box",
    background: theme.colors.background.paper.topbar,
    padding: `${theme.spacing(5)} ${theme.spacing(1)} ${theme.spacing(
      5
    )} ${theme.spacing(6)}`
  },

  header: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: `${theme.spacing(3)} ${theme.spacing(6)}`,
    backgroundColor: theme.colors.background.paper.lighter
  },
  headerItem: {
    width: 40
  }
}));

const ItemCart = observer((props: ItemCartProps) => {
  const { title, dataKey } = props;
  const { t } = useTranslation("settings");
  const css = useStyles();

  return (
    <div className={css.panelContainer}>
      <div className={css.header}>
        <Typography size="subtitle1(18px)" align="center" uppercase>
          {t(`settings:fields.${title}.title`)}
        </Typography>
      </div>
      <div className={css.scrollContainer}>
        {subscriptionStore.data[dataKey].map((item, i) => (
          <SubscriptionSettingsItem
            key={item._id}
            item={item}
            onClick={(item) => handleClick(item)}
          />
        ))}
      </div>
    </div>
  );

  function handleClick(item: SubscriptionOption) {
    subscriptionStore.toggleSubscription(item);
    subscriptionStore.toggleSubscriptionItem(item);
  }
});

export default ItemCart;
