import * as React from "react";
import { CreateSvgIcon } from "../icon";
import { IconNames, IconProps } from "../names";

export const Karma = (props: IconProps) => (
  <CreateSvgIcon {...props} data-cy={IconNames.Karma}>
    {({ color }) => (
      <>
        <g>
          <g>
            <path
              d="M17.4,22.18a5,5,0,0,1-1.17-.08c-.32-.86-1.18-3.76-.16-5.56a9.7,9.7,0,0,1,5.09-4.15,15.29,15.29,0,0,1,3-.78,15.86,15.86,0,0,1-.08,3.29A9.85,9.85,0,0,1,21.32,21C20.38,22,18.66,22.18,17.4,22.18Z"
              transform="translate(0)"
              fill="#7b8c99"
            />
            <path
              d="M17.4,21.79a5.79,5.79,0,0,0,3.76-1c1.72-1.56,2.35-4.07,2.59-5.79a12,12,0,0,0,.07-2.82,10.49,10.49,0,0,0-2.5.7,9.17,9.17,0,0,0-4.86,3.92c-.78,1.41-.31,3.76.08,4.93a2.89,2.89,0,0,0,.86.08m0,.86A10.82,10.82,0,0,1,16,22.57s-1.49-3.76-.23-6.18c2.5-4.62,8.85-5.17,8.85-5.17s.86,6.73-2.9,10.18A6.88,6.88,0,0,1,17.4,22.65Z"
              transform="translate(0)"
              fill="#fff"
            />
          </g>
          <g>
            <path
              d="M14.46,22.18h0a6.08,6.08,0,0,1-4-1.09A9.8,9.8,0,0,1,7.73,15a15.86,15.86,0,0,1-.08-3.29,17.2,17.2,0,0,1,3,.78,9.38,9.38,0,0,1,5.09,4.15c1,1.88.16,4.7-.15,5.56Z"
              transform="translate(0)"
              fill="#7b8c99"
            />
            <path
              d="M14.46,21.79h.86c.4-1.17.87-3.52.08-4.93a9.11,9.11,0,0,0-4.93-4A10.43,10.43,0,0,0,8,12.16C8,12.86,8,13.8,8,15a9.85,9.85,0,0,0,2.59,5.87,6.83,6.83,0,0,0,3.83.94h0m0,.86a6.85,6.85,0,0,1-4.3-1.25c-3.76-3.45-3-10.18-3-10.18s6.34.55,8.85,5.25c1.25,2.34-.24,6.1-.24,6.1A10,10,0,0,1,14.46,22.65Z"
              transform="translate(0)"
              fill="#fff"
            />
          </g>
          <g>
            <path
              d="M15.91,23.53c-.78-.47-3.6-2.43-4-4.86a10.75,10.75,0,0,1,.23-4.15A13.28,13.28,0,0,1,13.64,11a21.29,21.29,0,0,1,2.19-3.06A16.37,16.37,0,0,1,18,11a13.28,13.28,0,0,1,1.49,3.52,9.23,9.23,0,0,1,.24,4.08C19.43,20.87,16.69,23,15.91,23.53Z"
              transform="translate(0)"
              fill="#7b8c99"
            />
            <path
              d="M15.91,23c1.18-.86,3.21-2.66,3.52-4.46a8,8,0,0,0-.23-3.84,15.11,15.11,0,0,0-1.41-3.37,20.38,20.38,0,0,0-1.88-2.66A15.18,15.18,0,0,0,14,11.23a13.56,13.56,0,0,0-1.41,3.37,8.34,8.34,0,0,0-.23,3.92h0a6.76,6.76,0,0,0,2,3.29A8.15,8.15,0,0,0,15.91,23m0,1S12,21.73,11.53,18.67c-.94-6,4.38-11.27,4.38-11.27s5.33,5.4,4.31,11.27C19.75,21.57,15.91,24,15.91,24Z"
              transform="translate(0)"
              fill="#fff"
            />
          </g>
          <g>
            <path
              d="M20.73,24.67a12.07,12.07,0,0,1-4.46-1c0-.86.08-3.84,1.8-5.25a11.61,11.61,0,0,1,7.28-2.19,15.52,15.52,0,0,1,3.37.31,13.53,13.53,0,0,1-1.25,3.06,10.76,10.76,0,0,1-2,2.74,8,8,0,0,1-3.13,2A3.05,3.05,0,0,1,20.73,24.67Z"
              transform="translate(0)"
              fill="#cfab00"
            />
            <path
              d="M20.73,24.27A4.17,4.17,0,0,0,22.22,24a9.06,9.06,0,0,0,4.93-4.62,15.53,15.53,0,0,0,1.1-2.51,22.31,22.31,0,0,0-2.82-.23,11.38,11.38,0,0,0-7,2.11C17,20,16.74,22.32,16.74,23.41a11.11,11.11,0,0,0,4,.86m0,.87A14.09,14.09,0,0,1,15.88,24s-.32-4.07,1.88-5.87a11.94,11.94,0,0,1,7.59-2.28,17.92,17.92,0,0,1,3.84.4s-1.41,6.57-6.74,8.61A5.54,5.54,0,0,1,20.73,25.14Z"
              transform="translate(0)"
              fill="#fff"
            />
          </g>
          <g>
            <path
              d="M11,24.69h0a5.41,5.41,0,0,1-1.64-.23,8,8,0,0,1-3.13-2,14.36,14.36,0,0,1-2-2.74A13,13,0,0,1,3,16.63a16.76,16.76,0,0,1,3.44-.32c2.12,0,5.17.4,7.36,2.2,1.73,1.41,1.81,4.3,1.81,5.24A14.87,14.87,0,0,1,11,24.69Z"
              transform="translate(0)"
              fill="#7b8c99"
            />
            <path
              d="M11,24.3a12.38,12.38,0,0,0,4.08-.86c0-1-.24-3.45-1.65-4.62a11.57,11.57,0,0,0-7.12-2.11,13.28,13.28,0,0,0-2.82.23,14.53,14.53,0,0,0,1.09,2.51,10.08,10.08,0,0,0,2,2.66,7.28,7.28,0,0,0,3,2A4.73,4.73,0,0,0,11,24.3h0m0,.78a6,6,0,0,1-1.72-.23c-5.32-2-6.73-8.61-6.73-8.61a17.88,17.88,0,0,1,3.83-.4c2.35,0,5.41.47,7.6,2.28S15.9,24,15.9,24A14.28,14.28,0,0,1,11,25.08Z"
              transform="translate(0)"
              fill="#fff"
            />
          </g>
        </g>
      </>
    )}
  </CreateSvgIcon>
);
