import React from "react";
import { Theme } from "../../../lib/theme";
import { makeStyles } from "../../../hooks/makeStyles";
// import { AppLoader } from "ui/shared/app-loader";
import { observer } from "mobx-react";
// import { globalUIStore } from "global-stores/global-ui-store";
// import { userStore } from "global-stores/user-store";

import bg800 from "assets/jpg/bg-800.jpeg";
import bg1600 from "assets/jpg/bg-1600.jpeg";
import bg2500 from "assets/jpg/bg-2500.jpeg";
import bg4000 from "assets/jpg/bg-4000.jpeg";

const useStyles = makeStyles((theme: Theme) => ({
  bg: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100vh",
    backgroundColor: "#ccc",
    zIndex: -1
  },
  bgimg: {
    width: "100%",
    height: "100%"
  }
}));

export const Background = observer(() => {
  const css = useStyles();

  return (
    <picture className={css.bg}>
      <source media="(max-width: 799px)" srcSet={bg800} />
      <source media="(min-width: 2500px)" srcSet={bg4000} />
      <source media="(min-width: 1600px)" srcSet={bg2500} />
      <source media="(min-width: 800px)" srcSet={bg1600} />
      <img src={bg1600} alt="Inside.me background" className={css.bgimg}></img>
    </picture>
  );
});
